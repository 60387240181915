<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <li class="is-active"><a>{{ $t('breadcrumb_company_settings') }}</a></li>
            <router-link tag="li" :to="{ name: 'company-holidays', params: { cid } }"><a>{{ $t('breadcrumb_company_holidays') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>

    <section class="px-2 md:px-0 md:py-4 lg:py-8">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-8-desktop">
            <!-- year -->
            <sqr-input-number
              class="field w-64"
              label="company_holidays_year_choose"
              min="2000"
              v-model="year"
              size="large"
              max="2100"
              @change="load()"
            />
            <sqr-progress v-show="loading" />
            <sqr-error-note class="field" :error="loadError" />
          </div>
        </div>
      </div>
    </section>
    <!-- state -->
    <section class="px-2 md:px-0 md:py-4 lg:py-8" v-if="record">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-8-desktop">
            <div class="field">
              <sqr-select
                class="field"
                label="company_holidays_state_choose"
                v-model="state"
                :options="record.states"
                label-key="name"
                size="large"
                @select="prepare()"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- holidays -->
    <section class="px-2 md:px-0 md:py-4 lg:py-8" v-if="year && state">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-8-desktop">
            <div class="field">
              <label for="" class="label is-large">
                {{ $t('company_holidays_days_choose') }}
              </label>
              <div class="field help is-size-6">
                {{ $t('company_holidays_days_help') }}
              </div>
              <table class="table is-hoverable is-fullwidth">
                <tbody>
                  <tr
                    v-for="day in holidays"
                    :key="day.date"
                    @click="day.selected = !day.selected"
                  >
                    <td>
                      <input type="checkbox" v-model="day.selected" />
                    </td>
                    <td class="whitespace-no-wrap">
                      {{ day.date | weekDayLong }}
                    </td>
                    <td class="truncate">{{ day | name }}</td>
                    <td>{{ day.type }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- holidays -->
    <section class="px-2 md:px-0 md:py-4 lg:py-8" v-if="year && state">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-8-desktop">
            <div class="field">
              <label for="" class="label is-large">
                {{ $t('company_holidays_accounts_choose') }}
              </label>
              <sqr-progress v-show="orgLoading" />
              <sqr-error-note :error="orgLoadError" />

              <table class="table is-hoverable">
                <tbody>
                  <tr
                    v-for="item in org.content"
                    :key="item.id"
                    @click="
                      item.type === 'account' && toggle(accountIds, item.id)
                    "
                  >
                    <template v-if="item.type === 'team'">
                      <td></td>
                      <th colspan="1">{{ item.name }}</th>
                    </template>
                    <template v-if="item.type === 'account'">
                      <td>
                        <input
                          type="checkbox"
                          v-model="accountIds"
                          :value="item.id"
                        />
                      </td>
                      <td><account-name :account="item" reverse /></td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- -->
    <section class="px-2 md:px-0 md:pt-4 lg:pt-8 pb-64" v-if="year && state">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-8-desktop">
            <div class="buttons is-right">
              <sqr-button
                label="buttons_cancel"
                @click="$router.back()"
                color="white"
                size="large"
              />
              <sqr-button
                label="buttons_save"
                @click="save()"
                color="primary"
                size="large"
                :is-loading="saving"
              />
            </div>
            <sqr-error-note class="field" :error="saveError" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { contains, flatten } from 'ramda';
import company from './company';

import weekDayLong from '@/filters/weekDayLong';
import name from '@/filters/name';

import SqrInputNumber from '@/sqrd/SqrInputNumber';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrSelect from '@/sqrd/SqrSelect';
import SqrButton from '@/sqrd/SqrButton';
import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';

import weeks from 'sqr-wotime-core/dates/weeks';
import months from 'sqr-wotime-core/dates/months';
import generate from 'sqr-wotime-core/events/generateEventEntries';

export default {
  name: 'CompanyHolidays',
  mixins: [company],
  filters: { weekDayLong, name },
  components: {
    SqrInputNumber,
    SqrProgress,
    SqrErrorNote,
    SqrSelect,
    SqrButton,
    CompanyName,
    AccountName
  },
  computed: {
    ...mapState('holidays', ['exists', 'record', 'loading', 'loadError']),
    ...mapGetters('auth', ['uid', 'displayName']),
    ...mapState('org', {
      orgExists: 'exists',
      org: 'record',
      orgLoading: 'loading',
      orgLoadError: 'loadError'
    })
  },
  data() {
    return {
      year: new Date().getFullYear(),
      state: '',
      holidays: [],
      accountIds: [],
      saving: false,
      saveError: null
    };
  },
  mounted() {
    this.load();
    this.orgSub({
      path: ['companies', this.cid, 'orgs'],
      id: 'default'
    });
  },
  methods: {
    load() {
      this.sub({
        path: ['holidays'],
        id: this.year.toString()
      });
    },
    ...mapActions('holidays', ['sub']),
    ...mapActions('org', { orgSub: 'sub' }),
    prepare() {
      const state = this.state;
      this.holidays = this.record.holidays
        .filter(day => day.type === 'public')
        .filter(day => contains(state, day.states))
        .map(day => {
          const bank = contains(state, day.bank);
          const optional = contains(state, day.optional);
          let type = 'n/a';
          if (bank) {
            type = 'bank';
          } else if (optional) {
            type = 'non-work';
          } else {
            type = 'info';
          }
          const selected = bank && DateTime.fromISO(day.date).weekday < 6;
          return { ...day, selected, type };
        });
    },
    toggle(array, id) {
      const index = array.indexOf(id);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(id);
      }
    },
    async save() {
      try {
        this.saving = true;
        this.saveError = null;

        const companyId = this.cid;
        const model = this.company.entryModels.find(
          mod => mod.id === 'holiday'
        );

        const holidays = this.holidays.filter(day => day.selected);
        const accounts = this.org.content.filter(
          item => item.type === 'account' && contains(item.id, this.accountIds)
        );

        const events = flatten(
          holidays.map(day =>
            accounts.map(account => {
              return {
                companyId,
                accountId: account.id,
                familyName: account.familyName,
                givenName: account.givenName,
                email: account.email,
                commonId: 'e-h-' + day.date,
                startDate: day.date,
                endDate: day.date,
                months: months(day.date, day.date),
                weeks: weeks(day.date, day.date),
                name: day.name,
                name18: day.name18,
                status: 'accepted',
                accepted: {
                  date: new Date().toISOString(),
                  uid: this.uid,
                  displayName: this.displayName
                },
                entryModelId: model.id,
                entries: generate(day.date, day.date, [], model)
              };
            })
          )
        );

        const batch = this.$db().batch();
        const accountRef = this.$db()
          .collection('companies')
          .doc(companyId)
          .collection('accounts');
        events.forEach(event => {
          const ref = accountRef
            .doc(event.accountId)
            .collection('events')
            .doc(event.commonId);
          batch.set(ref, event);
        });
        await batch.commit();
        alert(this.$t('company_holidays_added'));
        this.$router.push({ name: 'planning', params: { cid: companyId } });
      } catch (error) {
        this.saveError = error;
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
