<template>
  <div>
    <label class="label" v-if="labelTr" :class="sizeClass">{{ labelTr }}</label>
    <div class="control has-icon-left" :class="controlClass">
      <div class="select" :class="sizeClass">
        <select :value="value" @change="change" :disabled="disabled">
          <option
            v-for="opt in mappedOptions"
            :key="opt.value"
            :value="opt.value"
            :selected="opt.selected"
          >
            {{ opt.label }}
          </option>
        </select>
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import label from './mixins/label';
import size from './mixins/size';
import options from './mixins/options';

export default {
  name: 'sqr-select',
  mixins: [label, size, options],
  props: {
    disabled: Boolean,
    hasIconsLeft: Boolean,
    isLoading: Boolean,
  },
  computed: {
    controlClass() {
      return {
        'has-icons-left': this.hasIconsLeft,
      };
    },
  },
};
</script>
